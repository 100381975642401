export default {
  phone: [
    {
      required: true,
      message: "请输入手机号"
    },
    {
      pattern:
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
      message: "请检查手机号格式"
    }
  ],
  domicile: {
    type: "array",
    required: true,
    message: "请选择户籍地址"
  },
  domicileTime: {
    required: true,
    message: "请选择户籍转入日期"
  },
  houseBelong: {
    required: true,
    message: "请选择购房者"
  },
  maritalStatus: {
    required: true,
    message: "请选择婚姻状态"
  },
  hourseType: {
    required: true,
    message: "请选择购房类型"
  },
  house: {
    type: "array",
    required: true,
    message: "请选择购房地址"
  },
  houseAddress: {
    required: true,
    message: "请输入详细地址"
  },
  bankId: {
    required: true,
    message: "请输入社会保障卡开户银行"
  },
  bankAccount: {
    required: true,
    message: "请输入社会保障卡金融账号"
  },
  bankBranch: {
    required: true,
    message: "请输入社会保障卡支行名称"
  },
  bankCode: {
    required: true,
    message: "请输入行号"
  }
};
