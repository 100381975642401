var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"form-wrapper"},[_c('van-form',{ref:"form"},[_c('van-divider',{style:({
        color: '#1989fa',
        borderColor: '#1989fa',
        padding: '0rem 0.3rem'
      }),attrs:{"content-position":"left"}},[_vm._v("基本信息")]),_c('div',{staticClass:"notices"},[_c('p',{staticClass:"notice-item"},[_vm._v("1、需申领过青年人才生活补贴，才可申领购房补贴")])]),_c('van-field',{attrs:{"readonly":"","disabled":"","name":"applyName","label":"用户名","placeholder":"请输入姓名","required":""},model:{value:(_vm.form.applyName),callback:function ($$v) {_vm.$set(_vm.form, "applyName", $$v)},expression:"form.applyName"}}),_c('van-field-select',{attrs:{"options":_vm.idCardTypes,"fieldProps":{
        label: '证件类型',
        required: true,
        readonly: true,
        disabled: true,
        placeholder: '请选择证件类型'
      }},model:{value:(_vm.form.idCardType),callback:function ($$v) {_vm.$set(_vm.form, "idCardType", $$v)},expression:"form.idCardType"}}),_c('van-field',{attrs:{"name":"phone","label":"手机号","placeholder":"请输入手机号","required":"","maxlength":"11"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('van-field',{attrs:{"readonly":"","disabled":"","name":"idCardNum","label":"证件号码","placeholder":"请输入证件号码","required":""},model:{value:(_vm.form.idCardNum),callback:function ($$v) {_vm.$set(_vm.form, "idCardNum", $$v)},expression:"form.idCardNum"}}),_c('van-field',{attrs:{"value":_vm.domicileStr,"name":"domicile","clickable":"","label":"户籍地址","placeholder":"请选择户籍地址","required":""},on:{"click":function($event){_vm.showDomiclePicker = true}}}),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.showDomiclePicker),callback:function ($$v) {_vm.showDomiclePicker=$$v},expression:"showDomiclePicker"}},[_c('van-picker',{attrs:{"show-toolbar":"","columns":_vm.domicleCitys},on:{"cancel":function($event){_vm.showDomiclePicker = false},"confirm":_vm.onDomicleConfirm}})],1),_c('van-field-calendar',{attrs:{"name":"domicileTime","calendarProps":{
        mode: 'single'
      },"fieldProps":{
        required: true,
        label: '户籍转入日期',
        placeholder: '请选择户籍转入日期'
      },"required":""},model:{value:(_vm.form.domicileTime),callback:function ($$v) {_vm.$set(_vm.form, "domicileTime", $$v)},expression:"form.domicileTime"}}),_c('van-field-select',{attrs:{"options":_vm.maritalStatusTypes,"fieldProps":{
        label: '婚姻状态',
        required: true,
        placeholder: '请选择婚姻状态'
      }},model:{value:(_vm.form.maritalStatus),callback:function ($$v) {_vm.$set(_vm.form, "maritalStatus", $$v)},expression:"form.maritalStatus"}}),_c('div',[_c('van-divider',{style:({
          color: '#1989fa',
          borderColor: '#1989fa',
          padding: '0rem 0.3rem'
        }),attrs:{"content-position":"left"}},[_vm._v("最高学历信息")]),_c('div',{staticClass:"notices"},[_c('p',{staticClass:"notice-item"},[_vm._v(" 1、限全日制博士研究生、硕士研究生、以及'双一流'建设高校的本科毕业生申请 ")])]),_c('van-field-select',{attrs:{"options":_vm.educations,"fieldProps":{
          label: '学历',
          placeholder: '请选择学历'
        }},model:{value:(_vm.form.education),callback:function ($$v) {_vm.$set(_vm.form, "education", $$v)},expression:"form.education"}}),_c('van-field-select',{attrs:{"options":_vm.educationTypes,"fieldProps":{
          label: '学历形式',
          placeholder: '请选择学历形式'
        }},model:{value:(_vm.form.educationType),callback:function ($$v) {_vm.$set(_vm.form, "educationType", $$v)},expression:"form.educationType"}}),_c('van-field',{attrs:{"name":"college","label":"毕业院校","placeholder":"请输入毕业院校","maxlength":"100"},model:{value:(_vm.form.college),callback:function ($$v) {_vm.$set(_vm.form, "college", $$v)},expression:"form.college"}}),_c('van-field',{attrs:{"name":"major","label":"所学专业","placeholder":"请输入所学专业","maxlength":"100"},model:{value:(_vm.form.major),callback:function ($$v) {_vm.$set(_vm.form, "major", $$v)},expression:"form.major"}}),_c('van-field-calendar',{attrs:{"name":"graduateDate","label":"毕业时间","placeholder":"毕业时间","fieldProps":{
          label: '毕业时间',
          placeholder: '请选择'
        }},model:{value:(_vm.form.graduateDate),callback:function ($$v) {_vm.$set(_vm.form, "graduateDate", $$v)},expression:"form.graduateDate"}})],1),_c('van-divider',{style:({
        color: '#1989fa',
        borderColor: '#1989fa',
        padding: '0rem 0.3rem'
      }),attrs:{"content-position":"left"}},[_vm._v("房屋信息")]),_c('div',{staticClass:"notices"},[_c('p',{staticClass:"notice-item"},[_vm._v("1、购房人为配偶时，家庭关系务必填写配偶信息")])]),_c('van-field-select',{attrs:{"name":"houseBelong","options":_vm.houseBelongs,"fieldProps":{
        label: '购房者',
        placeholder: '请选择购房者',
        required: true
      }},model:{value:(_vm.form.houseBelong),callback:function ($$v) {_vm.$set(_vm.form, "houseBelong", $$v)},expression:"form.houseBelong"}}),_c('van-field-select',{attrs:{"options":_vm.hourseTypeTypes,"fieldProps":{
        label: '购房类型',
        required: true,
        placeholder: '请选择购房类型'
      }},model:{value:(_vm.form.hourseType),callback:function ($$v) {_vm.$set(_vm.form, "hourseType", $$v)},expression:"form.hourseType"}}),_c('van-field',{attrs:{"value":_vm.houseStr,"name":"house","clickable":"","label":"购买住房地址","placeholder":"请选择购买住房地址","required":""},on:{"click":function($event){_vm.showHousePicker = true}}}),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.showHousePicker),callback:function ($$v) {_vm.showHousePicker=$$v},expression:"showHousePicker"}},[_c('van-picker',{attrs:{"show-toolbar":"","columns":_vm.houseCitys},on:{"cancel":function($event){_vm.showHousePicker = false},"confirm":_vm.onHouseConfirm}})],1),_c('van-field',{attrs:{"name":"houseAddress","label":"购房详细地址","placeholder":"请输入购房详细地址","required":"","maxlength":"100"},model:{value:(_vm.form.houseAddress),callback:function ($$v) {_vm.$set(_vm.form, "houseAddress", $$v)},expression:"form.houseAddress"}}),_c('div',[_c('van-divider',{style:({
          color: '#1989fa',
          borderColor: '#1989fa',
          padding: '0rem 0.3rem'
        }),attrs:{"content-position":"left"}},[_vm._v("家庭关系")]),_c('div',{staticClass:"notices"},[_c('p',{staticClass:"notice-item"},[_vm._v(" 1、已婚需要填写配偶及未成年子女信息，未婚无需填写 ")])]),_vm._l((_vm.form.family),function(item,i){return _c('div',{key:i},[_c('van-field-select',{attrs:{"options":_vm.familyTies,"fieldProps":{
            label: '关系',
            required: item.familyName || item.famIdCardNum ? true : false,
            placeholder: '请选择关系'
          }},model:{value:(item.familyTies),callback:function ($$v) {_vm.$set(item, "familyTies", $$v)},expression:"item.familyTies"}}),_c('van-field',{attrs:{"name":"familyName","label":"姓名","placeholder":"请输入姓名","required":item.familyName || item.famIdCardNum ? true : false,"maxlength":"20"},model:{value:(item.familyName),callback:function ($$v) {_vm.$set(item, "familyName", $$v)},expression:"item.familyName"}}),_c('van-field',{attrs:{"name":"famIdCardType","label":"证件类型","placeholder":"请输入证件类型","required":item.familyName || item.famIdCardNum ? true : false,"maxlength":"100","disabled":""},model:{value:(item.famIdCardType),callback:function ($$v) {_vm.$set(item, "famIdCardType", $$v)},expression:"item.famIdCardType"}}),_c('van-field',{attrs:{"name":"famIdCardNum","label":"身份证号","placeholder":"请输入身份证号","required":item.familyName || item.famIdCardNum ? true : false,"maxlength":"18"},model:{value:(item.famIdCardNum),callback:function ($$v) {_vm.$set(item, "famIdCardNum", $$v)},expression:"item.famIdCardNum"}}),_c('van-field-area',{attrs:{"name":"famDomicile","fieldProps":{
            required: item.familyName || item.famIdCardNum ? true : false,
            label: '户籍所在地',
            placeholder: '请选择户籍所在地'
          },"defaultField":""},model:{value:(item.famDomicile),callback:function ($$v) {_vm.$set(item, "famDomicile", $$v)},expression:"item.famDomicile"}}),_c('p',{staticClass:"fam-btns"},[_c('van-button',{staticClass:"btn",attrs:{"type":"default","size":"mini"},on:{"click":_vm.onAdd}},[_vm._v("新增")]),_c('van-button',{staticClass:"btn",attrs:{"type":"danger","size":"mini"},on:{"click":function($event){return _vm.onDelete(i)}}},[_vm._v("删除")])],1)],1)})],2),_c('van-divider',{style:({
        color: '#1989fa',
        borderColor: '#1989fa',
        padding: '0rem 0.3rem'
      }),attrs:{"content-position":"left"}},[_vm._v("申请人社保卡信息（金融账户）")]),_c('div',{staticClass:"notices"},[_c('p',{staticClass:"notice-item"},[_vm._v(" * 1、开户银行具体到支行和行号，若不清楚请拨打银行客服热线进行咨询，以下内容请务必准确无误，否则将影响补贴到账进度或产生退件 ")])]),_c('van-field',{attrs:{"name":"bankId","label":"社会保障卡开户银行","placeholder":"请输入社会保障卡开户银行","required":"","maxlength":"100"},model:{value:(_vm.form.bankId),callback:function ($$v) {_vm.$set(_vm.form, "bankId", $$v)},expression:"form.bankId"}}),_c('van-field',{attrs:{"name":"bankAccount","label":"社会保障卡金融账号","placeholder":"请输入社会保障卡金融账号","required":"","maxlength":"20"},model:{value:(_vm.form.bankAccount),callback:function ($$v) {_vm.$set(_vm.form, "bankAccount", $$v)},expression:"form.bankAccount"}}),_c('van-field',{attrs:{"name":"bankBranch","label":"社会保障卡支行名称","placeholder":"请输入社会保障卡支行名称","required":"","maxlength":"20"},model:{value:(_vm.form.bankBranch),callback:function ($$v) {_vm.$set(_vm.form, "bankBranch", $$v)},expression:"form.bankBranch"}}),_c('van-field',{attrs:{"name":"bankCode","label":"行号","placeholder":"请输入行号","required":"","maxlength":"20"},model:{value:(_vm.form.bankCode),callback:function ($$v) {_vm.$set(_vm.form, "bankCode", $$v)},expression:"form.bankCode"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }